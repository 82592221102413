/* =============================================================================
    JS - forms.js
   ========================================================================== */
//Rounding.init();

var Rounding = function(){

	return { init: init };

	function init(){

		//Initiate Operation Round Up
			var fileupload = document.getElementsByClassName("jobUploads");
			for(var i = 0; i < fileupload.length; i++){

				// Find all jobs with rounded corners
				if(fileupload[i].hasAttribute('data-radiusofcorners')){

					//Set base data
					var productcorners = $(fileupload[i]).attr('data-shape');
						if(productcorners == "Rounded 2 Corners"){productcorners = 2;}else{productcorners = 4}

					var productradius = $(fileupload[i]).attr('data-radiusofcorners');
						if(productradius == "Rounded 1/4"){productradius = .25;}else{productradius = .125}

					var productwidth = $(fileupload[i]).attr('data-width');
					var productheight = $(fileupload[i]).attr('data-height');

					//Determine Radius
					var radiusone = productradius / productwidth * 100;
					var radiustwo = productradius / productheight * 100;

					//4 Corners
					var radonetwo = radiusone + '% / ' + radiustwo + '%';
					var radtwoone = radiustwo + '% / ' + radiusone + '%';

					//2 Corners Front
					var radonetwononef = radiusone + '% 0 / ' + radiustwo + '% 0';
					var radtwoonenonef = radiustwo + '% 0 / ' + radiusone + '% 0';

					//2 Corners Back
					var radonetwononeb = '0 ' + radiusone + '% / 0 ' + radiustwo + '%';
					var radtwoonenoneb = '0 ' + radiustwo + '% / 0 ' + radiusone + '%';

					//Pull dimensions from img placeholder
					var imgsizef = $(fileupload[i]).find('.previewPrint .frontpreview .previewTrim img').attr('src').match(/\d+\.?\d*/g);
					var imgsizeb = $(fileupload[i]).find('.previewPrint .backpreview .previewTrim img').attr('src').match(/\d+\.?\d*/g);

					//var imgsizemf
					var imgsizemf = $('.previewMargins .frontpreview .previewTrim img').length ? $(fileupload[i]).find('.previewMargins .frontpreview .previewTrim img').attr('src').match(/\d+\.?\d*/g) : imgsizef;

					//var imgsizemf
					if($('.previewMargins .backpreview .previewTrim img').length){
						if (typeof $(fileupload[i]).find('.previewMargins .backpreview .previewTrim img').attr('src') !== "undefined"){
							var imgsizemb = $(fileupload[i]).find('.previewMargins .backpreview .previewTrim img').attr('src').match(/\d+\.?\d*/g)
						}else{
							var imgsizemb = imgsizeb;
						}
					}else{
						var imgsizemb = imgsizeb;
					}

					// console.log(typeof $(fileupload[i]).find('.previewMargins .frontpreview .previewTrim img').attr('src') + ' ' + $(fileupload[i]).find('.previewMargins .frontpreview .previewTrim img').attr('src'));
					// console.log(typeof $(fileupload[i]).find('.previewMargins .backpreview .previewTrim img').attr('src') + ' ' + $(fileupload[i]).find('.previewMargins .backpreview .previewTrim img').attr('src'));
					// console.log($(fileupload[i]).find('.previewPrint .frontpreview .previewTrim img').attr('src'));
					// console.log(imgsizemf + ' / ' + imgsizemb);

					// console.log(imgsizef[0] + '/' + imgsizef[1]);
					// console.log(imgsizeb[0] + '/' + imgsizeb[1]);

					//Preview View
					if(imgsizef[0]>imgsizef[1]){
						var cornerfront = productcorners > 2 ? radonetwo : radonetwononef;
					}else{
						var cornerfront = productcorners > 2 ? radtwoone : radtwoonenonef;
					}

					if(imgsizeb[0]>imgsizeb[1]){
						var cornerback = productcorners > 2 ? radonetwo : radonetwononeb;
					}else{
						var cornerback = productcorners > 2 ? radtwoone : radtwoonenoneb;
					}

					//Margin View
					if(imgsizemf[0]>imgsizemf[1]){
						var cornerfrontm = productcorners > 2 ? radonetwo : radonetwononef;
					}else{
						var cornerfrontm = productcorners > 2 ? radtwoone : radtwoonenonef;
					}

					if(imgsizemb[0]>imgsizemb[1]){
						var cornerbackm = productcorners > 2 ? radonetwo : radonetwononeb;
					}else{
						var cornerbackm = productcorners > 2 ? radtwoone : radtwoonenoneb;
					}

					if(imgsizef[0]>imgsizef[1]){
						$(fileupload[i]).find('.previewPrint .frontpreview div').css({'border-radius': cornerfront, 'background-clip': 'padding-box'});
						$(fileupload[i]).find('.previewMargins .frontpreview div').css({'border-radius': cornerfrontm, 'background-clip': 'padding-box'});
					}else{
						//reset for front vertical 2 corner
						if(productcorners == 2){
							cornerfront = '0 ' + radiustwo + '% / 0 ' + radiusone + '%';
							cornerfrontm = '0 ' + radiustwo + '% / 0 ' + radiusone + '%';
						}
						$(fileupload[i]).find('.previewPrint .frontpreview div').css({'border-radius': cornerfront, 'background-clip': 'padding-box'});
						$(fileupload[i]).find('.previewMargins .frontpreview div').css({'border-radius': cornerfrontm, 'background-clip': 'padding-box'});
					}

					if(imgsizeb[0]>imgsizeb[1]){
						$(fileupload[i]).find('.previewPrint .backpreview div').css({'border-radius': cornerback, 'background-clip': 'padding-box'});
						$(fileupload[i]).find('.previewMargins .backpreview div').css({'border-radius': cornerbackm, 'background-clip': 'padding-box'});
					}else{
						//reset for front vertical 2 corner
						if(productcorners == 2){
							cornerfront = radiustwo + '% 0 / ' + radiusone + '% 0';
							cornerfrontm = radiustwo + '% 0 / ' + radiusone + '% 0';
						}
						$(fileupload[i]).find('.previewPrint .backpreview div').css({'border-radius': cornerfront, 'background-clip': 'padding-box'});
						$(fileupload[i]).find('.previewMargins .backpreview div').css({'border-radius': cornerfrontm, 'background-clip': 'padding-box'});
					}

				}
			}

	}

}();
