/* =============================================================================
    JS - btn.js
   ========================================================================== */

var Btn = function(){

    var data = {
      buttons: [],
      animating: false,
      timer: '',
      current: ''
    }

    var options = {
      animate: false,
      animate_timer: 600,
      debug: false
    }

  	return {
      data: data,
      options: options,
      init: init,
      set: set,
      reset: reset,
      animate: animate
    };

  	function init(config){
      $.extend(options, options, config);
      log(options);
  	}

    function set(target, replace, text, animate){
      var current = target.html();
      var padding = '';
      var animate = options.animate;

      text = current.replace(replace, text);

      if(animate){
          Btn.animate();
          padding = '&nbsp;&nbsp;&nbsp;';
      }

      target.html(text + padding);

      data.buttons.push({
          target: target,
          text: current,
          placeholder: text
      });
    }

    function reset(){
        clearInterval(data.timer);
        for(var key in data.buttons){
            var btn = $(data.buttons[key].target);
            btn.html(data.buttons[key].text);
        };
        data.buttons = [];
    }

    function animate(){
        data.timer = setInterval(function(){
            var padding = '';
            data.current = data.current + '.';

            if(data.current === '....'){
                data.current = '.';
                padding = '&nbsp;&nbsp;';
            }else if(data.current === '.'){
                padding = '&nbsp;&nbsp;';
            }else if(data.current === '..'){
                padding = '&nbsp;';
            }

            for(var key in data.buttons){
                var btn = $(data.buttons[key].target);
                btn.html(data.buttons[key].placeholder + data.current + padding);
            };
        }, options.animate_timer);
    }

    function log(data){
        if(options.debug === true){
            console.log(data);
        }
    }

}();
