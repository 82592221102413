/* =============================================================================
    JS - builder.js
   ========================================================================== */
var Builder = function(){

	var allClasses = '';
	var direction = '';

	return {
		init: init,
		checkMoveForward: checkMoveForward,

		filter: filter,
		filterSize: filterSize,
		filterSides: filterSides,
		filterUV: filterUV,
		filterSpotUV: filterSpotUV,
		filterFoil: filterFoil,
		filterWeight: filterWeight,
		filterCorners: filterCorners,

		initSlide: initSlide,
		initSize: initSize,
		initSides: initSides,
		initUV: initUV,
		initSpotUV: initSpotUV,
		initFoil: initFoil,
		initWeight: initWeight,
		initCorners: initCorners,
		initFinal: initFinal,

		setCache: setCache,
		stepInit: stepInit,
		stepInitSecondary: stepInitSecondary,
		setActive: setActive,
		setActiveSecondary: setActiveSecondary,
		checkOptions: checkOptions,
		checkSelected: checkSelected,
		checkPanel: checkPanel,
		showStep: showStep,
		activeButton: activeButton
	};

	function init(){

		Builder.cache = [];
		Builder.opts = {};
		Builder.cache[0] = selectedProds;
		Builder.direction = 'forward';

		/**
		 * Gather All Classes
		 */
		var $i = 0;
		$('.builderStep').each(function(){
			Builder.allClasses += $(this).attr('data-labels');
		});

		/**
		 * When next step or get started button is clicked.
		 */
		$('.stepbtn').click(function(e) {
			Builder.direction = 'forward';
			Builder.checkMoveForward();
			e.preventDefault();
		});

		/**
		 * When prev step button is clicked.
		 */
		$('.stepbtnback').click(function(e) {
			Builder.direction = 'back';
			$('.builderStep.builderActive').removeClass('builderActive').hide().prev('.builderStep').show().addClass('builderActive');

			Builder.checkPanel();
			Builder.initSlide($('.builderStep.builderActive'));
			e.preventDefault();
		});

	    $('.optionButton.primary label').click(function() {
	    	Builder.stepInit($(this));
	    	Builder.setActive($(this));
	    });

	    $('.optionButton.secondary label').click(function() {
	    	Builder.stepInitSecondary($(this));
	    	Builder.setActiveSecondary($(this));
	    });

	    $('.numcornerOption label').click(function(){
	    	$('.numcornerOption').removeClass('active');
	    	$(this).parent().addClass('active');
	    });

	    $('.sizecornerOption label').click(function(){
	    	$('.sizecornerOption').removeClass('active');
	    	$(this).parent().addClass('active');
	    });

	}

	function checkMoveForward(){
		if(Builder.checkSelected($('.builderStep.builderActive')) && Builder.checkOptions($('.builderStep.builderActive'))){
			Builder.filter($('.builderStep.builderActive'));

			$('.builderStep.builderActive').removeClass('builderActive').hide().next('.builderStep').show().addClass('builderActive');
			Builder.checkPanel();

			Builder.initSlide($('.builderStep.builderActive'));
		}
	}

	/**
	 * Filters
	 *
	 * Filters are fired at the end of a slide once next is clicked.
	 * This filters out all products that don't match the selected options.
	 */
	function filter($this){
		var filter = $this.attr('data-fire');

		if(filter === 'size'){ Builder.filterSize(); }
		else if(filter === 'sides'){ Builder.filterSides(); }
		else if(filter === 'uv'){ Builder.filterUV(); }
		else if(filter === 'spotuv'){ Builder.filterSpotUV(); }
		else if(filter === 'foil'){ Builder.filterFoil(); }
		else if(filter === 'weight'){ Builder.filterWeight(); }
		else if(filter === 'corners'){ Builder.filterCorners(); }
	}

	// Filter: Size
	function filterSize(){
		var $target = $('input[name=sizeOption]:checked');
		var $size = $target.val();
		var $w = $target.attr('data-width');
		var $h = $target.attr('data-height');
		var $oval = $target.attr('data-oval');

		$('.uploadRight').attr('data-width',$w);
		$('.uploadRight').attr('data-height',$h);

		var newSelection = [];

		for (var key in selectedProds){
			if($oval === '1'){
				if(selectedProds[key].size === $size && selectedProds[key].oval === '1')
					newSelection.push(selectedProds[key]);
			}else{
				if(selectedProds[key].size === $size && selectedProds[key].oval === '0')
					newSelection.push(selectedProds[key]);
			}
		}

		selectedProds = newSelection;
	}

	// Filter: Sides
	function filterSides(){
		var newSelection = [];
		var $availableSides = [];
		var $side = $('input[name=sideOption]:checked');

		if($side.val() === '4/0' || $side.val() === '5/0'){
			$availableSides.push('4/0');
			$availableSides.push('0/4');
			$availableSides.push('5/0');
			$availableSides.push('0/5');
		}else{
			$availableSides.push('4/4');
			$availableSides.push('4/5');
			$availableSides.push('5/4');
			$availableSides.push('5/5');
		}

		for (var key in selectedProds){
			selectedProds[key].side = $side.val();
			for (var side in selectedProds[key].sides){
				if(selectedProds[key].sides[side] === $side.val()){
					selectedProds[key].colorspec_uuid = side;
				}

				if($.inArray(selectedProds[key].sides[side], $availableSides) !== -1){
					newSelection.push(selectedProds[key]);
				}
			}
		}

		selectedProds = newSelection;
	}

	// Filter: UV
	function filterUV(){
		var $newSelection = [];
		var $available = [];
		var $uv = $('input[name=uvOption]:checked').val();

		if($uv === '0'){
			for (var key in selectedProds){
				if(selectedProds[key].uv === '0' && selectedProds[key].uv_front === '0' && selectedProds[key].uv_back === '0'){
					$newSelection.push(selectedProds[key]);
				}
			}
		}else if($uv === 'uv'){
			for (var key in selectedProds){
				if(selectedProds[key].uv === '1'){
					$newSelection.push(selectedProds[key]);
				}
			}
		}else if($uv === 'uv_front'){
			for (var key in selectedProds){
				if(selectedProds[key].uv_front === '1'){
					$newSelection.push(selectedProds[key]);
				}
			}
		}else if($uv === 'uv_back'){
			for (var key in selectedProds){
				if(selectedProds[key].uv_back === '1'){
					$newSelection.push(selectedProds[key]);
				}
			}
		}

		selectedProds = $newSelection;
	}

	// Filter: Spot UV
	function filterSpotUV(){
		var $newSelection = [];
		var $available = [];
		var $spot = $('input[name=spotOption]:checked').val();

		if($spot === '-1'){
			for (var key in selectedProds){
				if(selectedProds[key].spot_uv === '0' && selectedProds[key].spot_uv_front === '0' && selectedProds[key].spot_uv_back === '0'){
					$newSelection.push(selectedProds[key]);
				}
			}
		}else if($spot === '5/0'){
			for (var key in selectedProds){
				if(selectedProds[key].spot_uv === '1' && selectedProds[key].spot_uv_front === '1' && selectedProds[key].spot_uv_back === '0'){
					$newSelection.push(selectedProds[key]);
				}
			}
		}else if($spot === '0/5'){
			for (var key in selectedProds){
				if(selectedProds[key].spot_uv === '1' && selectedProds[key].spot_uv_front === '0' && selectedProds[key].spot_uv_back === '1'){
					$newSelection.push(selectedProds[key]);
				}
			}
		}else if($spot === '5/5'){
			for (var key in selectedProds){
				if(selectedProds[key].spot_uv === '1' && selectedProds[key].spot_uv_front === '0' && selectedProds[key].spot_uv_back === '0'){
					$newSelection.push(selectedProds[key]);
				}
			}
		}

		selectedProds = $newSelection;
	}

	// Filter: Foil
	function filterFoil(){
		Builder.opts.foil = [$('input[name=foilOption]:checked').val(), $('select[name=foilColor]').val()];

		var newSelection = [];
		var $availableSides = [];
		var $side = $('input[name=foilOption]:checked');

		for (var key in selectedProds){
			if(selectedProds[key].foil === '1'){
				newSelection.push(selectedProds[key]);
			}
		}

		selectedProds = newSelection;

		// var $newSelection = [];
		// var $available = [];
		// var $foil = $('input[name=foilOption]:checked').val();
		// var $side = $('input[name=sideOption]:checked').val();

		// if($foil === '5/5'){
		// 	for (var key in selectedProds){
		// 		var selected = 0;
		// 		for(var side in selectedProds[key].sides){
		// 			if(selectedProds[key].sides[side] === '5/5'){
		// 				selected = 1;
		// 			}
		// 		}
		// 		if(selected === 1){
		// 			selectedProds[key].side = '5/5';
		// 			$newSelection.push(selectedProds[key]);
		// 		}
		// 	}
		// }else if($foil === '5/0'){
		// 	for (var key in selectedProds){
		// 		var selected = 0;
		// 		for(var side in selectedProds[key].sides){
		// 			if(selectedProds[key].sides[side] === '5/0' || selectedProds[key].sides[side] === '5/4'){
		// 				selected = 1;
		// 			}
		// 		}
		// 		if(selected === 1){
		// 			if($side === '5/5'){
		// 				selectedProds[key].side = '5/4';
		// 			}else{
		// 				selectedProds[key].side = '5/0';
		// 			}
		// 			$newSelection.push(selectedProds[key]);
		// 		}
		// 	}
		// }else if($foil === '0/5'){
		// 	for (var key in selectedProds){
		// 		var selected = 0;
		// 		for(var side in selectedProds[key].sides){
		// 			if(selectedProds[key].sides[side] === '0/5' || selectedProds[key].sides[side] === '4/5'){
		// 				selected = 1;
		// 			}
		// 		}
		// 		if(selected === 1){
		// 			if($side === '5/5'){
		// 				selectedProds[key].side = '4/5';
		// 			}else{
		// 				selectedProds[key].side = '0/5';
		// 			}
		// 			$newSelection.push(selectedProds[key]);
		// 		}
		// 	}
		// }

		// selectedProds = $newSelection;
	}

	// Filter: Weight
	function filterWeight(){
		var $weight = $('input[name=paperweight]:checked').val();
		var newSelection = [];

		for (var key in selectedProds){
			if(selectedProds[key].weight === $weight)
				newSelection.push(selectedProds[key]);
		}

		selectedProds = newSelection;
	}

	// Filter: Corners
	function filterCorners(){
		if($('input[name=cornerOption]:checked').val() === '1'){
			Builder.opts.corners = [$('input[name=numcornerOption]:checked').val(), $('input[name=sizecornerOption]:checked').val()];
		}

		var $corners = $('input[name=cornerOption]:checked').val();
		var newSelection = [];

		for (var key in selectedProds){
			if(selectedProds[key].rounded_corners === $corners)
				newSelection.push(selectedProds[key]);
		}

		selectedProds = newSelection;
	}

	/**
	 * Inits
	 *
	 * Inits are called when a slide is loaded.
	 * This hides all slide options that aren't availble in the currently selected products list.
	 */
	function initSlide($this){
		var filter = $this.attr('data-fire');

		Builder.setCache(filter);

		if(filter === 'size'){ Builder.initSize(); }
		else if(filter === 'sides'){ Builder.initSides(); }
		else if(filter === 'uv'){ Builder.initUV(); }
		else if(filter === 'spotuv'){ Builder.initSpotUV(); }
		else if(filter === 'foil'){ Builder.initFoil(); }
		else if(filter === 'weight'){ Builder.initWeight(); }
		else if(filter === 'corners'){ Builder.initCorners(); }
		else if(filter === 'final'){ Builder.initFinal(); }

		if(filter !== 'final' && filter !== undefined){
			// Activate first select option on slide when it loads in
			var firstElement = $('.builderStep.builderActive div.optionButton:not(.hide):first label');
	    	Builder.stepInit(firstElement);
			Builder.setActive(firstElement);
		}

	}

	// Init: Size Slide
	function initSize(){

		var available = [];

		// Loop selected products and build list of available sizes
		for (var key in selectedProds){
			if($.inArray(selectedProds[key].size, available) === -1){
				available.push(selectedProds[key].size);
			}
			if($.inArray(selectedProds[key].size, 'oval') === -1 && selectedProds[key].oval === '1'){
				available.push('oval');
			}
		}

		// Hide the sizes in this slide that don't apply to what's available in our current product list
		$('input[name=sizeOption]').each(function(){
			if($.inArray($(this).val(), available) === -1 || $(this).attr('data-oval') === '1' && $.inArray('oval', available) === -1){ $(this).parent().addClass('hide'); }else{ $(this).parent().removeClass('hide'); }
		});
	}

	// Init: Sides Slide
	function initSides(){

		var available = [];

		// Loop selected products and build list of available sizes
		for (var key in selectedProds){
			for (var side in selectedProds[key].sides){
				if($.inArray(selectedProds[key].sides[side], available) === -1){
					available.push(selectedProds[key].sides[side]);
				}
			}
		}

		// Hide the sizes in this slide that don't apply to what's available in our current product list
		$('input[name=sideOption]').each(function(){
			if($.inArray($(this).val(), available) === -1){	$(this).parent().addClass('hide'); }else{ $(this).parent().removeClass('hide'); }
		});
	}

	// Init: UV Slide
	function initUV(){

		var selector = '';
		var availableSpots = [];

		// Loop selected products and build list of available spot options
		for (var key in selectedProds){

			selector = '';

			if(selectedProds[key].uv === '1')
				selector = 'uv';
			else if(selectedProds[key].uv_front === '1')
				selector = 'uv_front';
			else if(selectedProds[key].uv_back === '1')
				selector = 'uv_back';
			else if(selectedProds[key].uv === '0' && selectedProds[key].uv_front === '0' && selectedProds[key].uv_back === '0')
				selector = '0';

			if($.inArray(selector, availableSpots) === -1){
				availableSpots.push(selector);
			}

		}

		// Hide the sizes in this slide that don't apply to what's available in our current product list
		$('input[name=uvOption]').each(function(){
			if($.inArray($(this).val(), availableSpots) === -1){	$(this).parent().addClass('hide'); }else{ $(this).parent().removeClass('hide'); }
		});
		if(availableSpots.length === 1 && Builder.direction === 'forward'){
			setTimeout(function(){ Builder.checkMoveForward() }, 1);
		}
	}

	// Init: Spot UV Slide
	function initSpotUV(){

		var selector = '';
		var availableSpots = [];

		// Loop selected products and build list of available spot options
		for (var key in selectedProds){

			selector = '';

			if(selectedProds[key].spot_uv === '1' && selectedProds[key].spot_uv_front === '0' && selectedProds[key].spot_uv_back === '0')
				selector = '5/5';
			else if(selectedProds[key].spot_uv === '1' && selectedProds[key].spot_uv_front === '1' && selectedProds[key].spot_uv_back === '0')
				selector = '5/0';
			else if(selectedProds[key].spot_uv === '1' && selectedProds[key].spot_uv_front === '0' && selectedProds[key].spot_uv_back === '1')
				selector = '0/5';
			else if(selectedProds[key].spot_uv === '0' && selectedProds[key].spot_uv_front === '0' && selectedProds[key].spot_uv_back === '0')
				selector = '-1';

			if($.inArray(selector, availableSpots) === -1){
				availableSpots.push(selector);
			}

		}

		// Hide the sizes in this slide that don't apply to what's available in our current product list
		$('input[name=spotOption]').each(function(){
			if($.inArray($(this).val(), availableSpots) === -1){	$(this).parent().addClass('hide'); }else{ $(this).parent().removeClass('hide'); }
		});
		if(availableSpots.length === 1 && Builder.direction === 'forward'){
			setTimeout(function(){ Builder.checkMoveForward() }, 1);
		}
	}

	// Init: Foil Slide
	function initFoil(){
		Builder.opts['foil'] = [];

		var availableFoil = [];
		availableFoil.push('8963b1f4-d491-4436-a739-4689e3fe9f9b');
		availableFoil.push('07215cdd-dd1d-415a-90e5-3991e3339c25');
		availableFoil.push('8617380b-7af3-4378-af16-c004a8e48ed4');

		// Loop selected products and build list of available foil options
		// for (var key in selectedProds){
			// for (var side in selectedProds[key].sides){
			// if($.inArray(selectedProds[key].side, availableFoil) === -1 && selectedProds[key].side === '5/5'){
			// 	availableFoil.push('5/5');
			// 	availableFoil.push('5/0');
			// 	availableFoil.push('0/5');
			// }else if($.inArray(selectedProds[key].side, availableFoil) === -1 && selectedProds[key].side === '5/0'){
			// 	availableFoil.push('5/0');
			// }
			// }
		// }

		// Hide the sizes in this slide that don't apply to what's available in our current product list
		$('input[name=foilOption]').each(function(){
			if($.inArray($(this).val(), availableFoil) === -1){	$(this).parent().addClass('hide'); }else{ $(this).parent().removeClass('hide'); }
		});
	}

	// Init: Weight Slide
	function initWeight(){

		var available = [];

		// Loop selected products and build list of available sizes
		for (var key in selectedProds){
			if($.inArray(selectedProds[key].weight, available) === -1){
				available.push(selectedProds[key].weight);
			}
		}

		// Hide the sizes in this slide that don't apply to what's available in our current product list
		$('input[name=paperweight]').each(function(){
			if($.inArray($(this).val(), available) === -1){	$(this).parent().addClass('hide'); }else{ $(this).parent().removeClass('hide'); }
		});
		if(available.length === 1 && Builder.direction === 'forward'){
			setTimeout(function(){ Builder.checkMoveForward() }, 1);
		}
		// setTimeout(function(){
		// 	if(available.length < 2){
		// 		Builder.filter($('.builderStep.builderActive'));
		// 		$('.builderStep.builderActive').removeClass('builderActive').hide().next('.builderStep').show().addClass('builderActive');
		// 		Builder.checkPanel();
		// 		Builder.initSlide($('.builderStep.builderActive'));
		// 	}
		// }, 1);
	}

	// Init: Corners Slide
	function initCorners(){

		Builder.opts.corners = [];
		var available = [];
		var cornerCount = [];
		var cornerRadius = [];

		// for (var key in selectedProds){
		// 	for (var side in selectedProds[key].sides){
		// 		if($.inArray(selectedProds[key].sides[side], available) === -1){
		// 			available.push(selectedProds[key].sides[side]);
		// 		}
		// 	}
		// }

		// Loop selected products and build list of available sizes
		for (var key in selectedProds){
			if($.inArray(selectedProds[key].rounded_corners, available) === -1){
				available.push(selectedProds[key].rounded_corners);

				if(selectedProds[key].rounded_corners === '1'){
					for(var x in selectedProds[key].corner_count){
						if($.inArray(selectedProds[key].corner_count[x], cornerCount) === -1){
							cornerCount.push(selectedProds[key].corner_count[x]);
						}
					}
					for(var x in selectedProds[key].corner_radius){
						if($.inArray(selectedProds[key].corner_radius[x], cornerRadius) === -1){
							cornerRadius.push(selectedProds[key].corner_radius[x]);
						}
					}
				}
			}
		}

		// Hide the sizes in this slide that don't apply to what's available in our current product list
		$('input[name=cornerOption]').each(function(){
			if($.inArray($(this).val(), available) === -1){	$(this).parent().addClass('hide'); }else{ $(this).parent().removeClass('hide'); }
		});

		$('input[name=numcornerOption]').each(function(){
			if($.inArray($(this).val(), cornerCount) === -1){	$(this).parent().addClass('hide'); }else{ $(this).parent().removeClass('hide'); }
		});

		$('input[name=sizecornerOption]').each(function(){
			if($.inArray($(this).val(), cornerRadius) === -1){	$(this).parent().addClass('hide'); }else{ $(this).parent().removeClass('hide'); }
		});
	}

	function initFinal(){
		setTimeout(function(){
			$('.breadcrumbs').slideUp('fast', function(){
				$('.prod_title').html(selectedProds[0].description).slideDown('fast');
			});
		}, 800);

		// Get pricing & quanities
		var options = [];
		options[0] = $('#builderFoil input[type=radio]:checked').val();

		$.post('/business-cards/builder/pricing', {product: selectedProds[0], options: options}, function(data){
			var runsize = $('select[name=runsize]');

			runsize.find('option').remove().end();

			for (var key in data.runsizes){
				if(data.runsizes[key].digital === '1'){
					runsize.append('<option value="'+key+'" data-price="'+data.runsizes[key].price+'" data-val="'+data.runsizes[key].runsize+'" data-group="'+data.runsizes[key].group_ship+'" data-showdigital="1">'+data.runsizes[key].runsize+' ($'+data.runsizes[key].price+') *</option>');
				}else{
					runsize.append('<option value="'+key+'" data-price="'+data.runsizes[key].price+'" data-val="'+data.runsizes[key].runsize+'" data-group="'+data.runsizes[key].group_ship+'">'+data.runsizes[key].runsize+' ($'+data.runsizes[key].price+')</option>');
				}
			}
			$('.designServices').html(data.design_dropdown);

			runsize.trigger('change');

			//MARK QUANTITIES WITH FREE SHIPPING
			$('select[name=runsize]').next('span').html('<strong>Quantity per Set</strong>');
			var freeship = [
				'79ab9a9c-e3f9-4a05-9d1c-f34464093ceb',
				'7d553b95-226d-4961-87c0-14601661e4b5',
				'cb3457f6-2cdb-4225-bd1f-96c2926b5e57',
				'0b9f6bb8-80d7-4322-bfda-bc89b217910f',
				'90988145-57a1-40c6-a29e-904ecd3f96e1',
				'91efff4d-de64-410a-8794-fc547022a7f2'
			];
			var freeqty = ['1000', '2500', '5000'];
			if($.inArray(selectedProds[0].uuid, freeship) > -1){
				for(i=0; i<freeqty.length; i++){
					var optval = $('select[name=runsize] option[data-val="'+freeqty[i]+'"]').text();
					$('select[name=runsize] option[data-val="'+freeqty[i]+'"]').text(optval + ' - free shipping');
				}
				if($('select[name=runsize]').next('span').children('a').length == 0){
						$('select[name=runsize]').next('span').prepend('<a href="#" class="modal-link" data-icon="&#xe033;" onclick="$(\'#modal\').modal({show: true,remote: \'/modal/freeship\'}); return false;"></a>');
				}
			};

		});
	}

	function setCache($filter){
		var $i = 0;
		var $erase = 0;

		$('.builderStep').each(function(){
			if($(this).attr('data-fire') === $filter){
				$x = $i + 1;
				if(Builder.cache[$x] === undefined){
					// Roll Forward
					Builder.cache[$i] = selectedProds;
				}else{
					selectedProds = Builder.cache[$i];
				}
				$erase = 1;
			}else if($erase === 1){
				Builder.cache[$i] = undefined;
			}
			++$i;
		});

	}

	/**
	 * Action to call
	 */
	function stepInit($this){
		var container = $this.closest('.builderStep');
		var text = container.attr('data-text');
		var classes = container.attr('data-labels');

		$('.breadcrumbs').show();
		$('.prod_title').hide();

		if($this.hasClass('showSubs')){
			container.find('.extraOptions').show();
		}else{
			container.find('.extraOptions').hide();
		}
		container.find('.optionButton.secondary.active label').each(function(){
			Builder.setActiveSecondary($(this));
		});

    	$('.'+text).removeClass('hide').text(' | ' + $this.text());
    	$('.builderview').removeClass(classes).addClass($this.parent().children('input').attr('data-class'));
	}

	/**
	 * Action to call on secondary clicks
	 */
	function stepInitSecondary($this){
		var group = $this.closest('.optionsGroup');
		var text = group.attr('data-text');
		var classes = group.attr('data-labels');

    	$('.'+text).removeClass('hide').text(' | ' + $this.text());
    	$('.builderview').removeClass(classes).addClass($this.parent().children('input').attr('data-class'));
	}

	/**
	 * Set active primary button when clicked
	 */
	function setActive($this){
		$this.closest('.builderStep').find('.optionButton.primary').removeClass('active');
		$this.closest('.builderStep').find('.primary input[type=radio]').removeAttr('checked');

		$this.prev('input').attr('checked', true);
		if ($this.closest('input').prop('checked', true)){
			$this.closest('.optionButton').addClass('active');
		}
	}

	/**
	 * Set active secondary button when clicked
	 */
	function setActiveSecondary($this){
		//$this.closest('.optionsGroup').find('.optionButton').removeClass('active');
		//$this.closest('.optionsGroup').find('input[type=radio]').removeAttr('checked');

		$this.prev('input').attr('checked', true);
		if ($this.closest('input').prop('checked', true)){
			$this.closest('.optionButton').addClass('active');
		}
	}

	function checkOptions($this){
		var options = $this.find('.optionRequired select');

		if( ! $this.is("#builderStart")){
			$pass = 1;
			options.each(function(){
				if($(this).val() === '-1'){
					$pass = 0;
				}
			});

			if($pass === 1){
				return true;
			}else{
				Growl.showhide('Please select all options to continue');
				return false;
			}
		}else{
			return true;
		}

	}

	/**
	 * When next is clicked we check that an option has been selected on this slide before continuing
	 */
	function checkSelected($this){
		var selected = $this.find('input:checked');

		if( ! $this.is("#builderStart")){

			if(selected.parent().css('display') === 'none' || selected.length === 0){
				Growl.showhide('Please select an option to continue');
				return false;
			}else{
				// Check for Sub Option Groups
				var subSelect = $this.find('.extraOptions');
				if(subSelect.length === 1 && subSelect.css('display') !== 'none'){
					var $pass = 1;
					$this.find('.optionsGroup').each(function(){
						var selected = $(this).find('input:checked');
						if(selected.parent().css('display') === 'none' || selected.length === 0){
							$pass = 0;
						}
					});

					if($pass === 1){
						return true;
					}else{
						Growl.showhide('Please select all options to continue');
						return false;
					}

				}else{
					return true;
				}
			}

		}else{
			return true;
		}
	}

	/**
	 * Check if this slide is the start or end
	 */
	function checkPanel() {
		if($('.builderStep.builderActive').is("#builderStart") ) {
			$('.builderStart').show();
			$('.builderNav, .builderFinish').hide();
		}else if ($('.builderStep.builderActive').is("#builderSummary") ){
			$('.builderFinish').show();
			$('.builderNav, .builderStart').hide();
		}else{
			if($('.builderStep.builderActive').is('#builderSides') && $('input[name=sideOption]:checked').val() === '4/4'){
				$('.builderview').removeClass('singlesided').addClass('doublesided');
			}

			$('.builderNav').show();
			$('.builderFinish, .builderStart').hide();
		}
	}

	function showStep($class){
		$('.builderStep').hide();
		$($class).show();
	}

	function activeButton($class){
		$($class + " .optionButton").removeClass("active");
		$($class + " .optionDefault").addClass("active");
		$($class + " .optionDefault input").prop('checked', true);
	}

}();
