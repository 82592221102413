/** IMGIX Images **/
var bgOptions = {
  fluidClass: 'imgix-fluid-bg',
  updateOnResizeDown: true,
  pixelStep: 10,
  autoInsertCSSBestPractices: true,
  lazyLoad: true,
  lazyLoadOffsetVertical: 100
};
imgix.onready(function() {
  imgix.fluid(bgOptions);
});

/** IMGIX Images **/
$(document).ready(function() {
	function getDevicePixelRatio() {
		var dpr = 1;
		if (typeof window.devicePixelRatio !== "undefined") {
			dpr = window.devicePixelRatio;
		}
		return dpr;
	}
	window.lastDevicePixelRatio = 1.0;
	function upscaleImages() {
	var dpr = getDevicePixelRatio();
	if (window.lastDevicePixelRatio < dpr) {
		$(".dpr-compat").each(function () {
			var dpr = getDevicePixelRatio();
			var elem = $(this);
			var tag = elem.get(0).tagName;
			if (tag === "IMG") {
				var src = elem.attr("src");
				// dpr only works with heights and widths specified.
				if (typeof src !== "undefined" && src.match(/(&|\?)(w|h)=[0-9]+/gi) !== null) {
					// set the dpr value.
					if (src.match(/([&\?])dpr=[0-9\.]+/gi) !== null) {
						src = src.replace(/([&\?])dpr=[0-9\.]+/gi, "$1dpr=" + dpr.toString());
					} else if (src.indexOf("?") == -1) {
						src = src + "?dpr=" + dpr.toString();
					} else {
						src = src + "&dpr=" + dpr.toString();
					}
					// set the new src.
					elem.attr("src", src);
				};
			};
			var backgroundImage = elem.css("background-image")
			// dpr only works with heights and widths specified.
			if (typeof backgroundImage !== "undefined") {
				backgroundImage = backgroundImage.replace(/^url\((.+)\)$/gi, "$1");
				if (backgroundImage.match(/(&|\?)(w|h)=[0-9]+/gi) !== null) {
					// set the dpr value.
					if (backgroundImage.match(/(&|\?)dpr=[0-9\.]+/gi) !== null) {
						backgroundImage = backgroundImage.replace(/(&|\?)dpr=[0-9]\.]+/gi, "$1dpr=" + dpr.toString());
					} else if (backgroundImage.indexOf("?") == -1) {
						backgroundImage = backgroundImage + "?dpr=" + dpr.toString();
					} else {
						backgroundImage = backgroundImage + "&dpr=" + dpr.toString();
					}
					// set the new backgroundImage.
					elem.css("background-image", "url(" + backgroundImage + ")");
				};
			};
		});
	}
		window.lastDevicePixelRatio = Math.max(window.parseFloat(dpr), window.parseFloat(window.lastDevicePixelRatio));
	}
	upscaleImages();
	window.setInterval(upscaleImages, 1000); /* Every so often, check DPR. */
	/** END IMGIX Images **/
});

// @codekit-prepend  "imgix.js", "imgix.jquery.js";
